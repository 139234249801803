import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Backdrop
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateProfileApi } from '../../services/ProfileApi';
import { Profile, UpdateProfile } from '../../types/profile'; // Adjust the import path as necessary
import { useProfile } from '../../contexts/ProfileContext'; // Import profile context

const MySwal = withReactContent(Swal);

interface UpdateProfileDialogProps {
  open: boolean;
  onClose: () => void;
  profile: Profile;
  onProfileUpdate: (updatedProfile: Profile) => void;
}

const UpdateProfileDialog: React.FC<UpdateProfileDialogProps> = ({ open, onClose, profile, onProfileUpdate }) => {
  const [loading, setLoading] = useState(false);
  const { refetchProfile } = useProfile(); // Access refetchProfile function from context

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'First Name must contain only alphabetical characters')
      .nullable(),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'Last Name must contain only alphabetical characters')
      .nullable(),
    position: Yup.string()
      .matches(/^(?!.*\b\d+\b)[a-zA-Z0-9]+$/, 'Position must contain letters and can optionally include numbers, but cannot be just numbers')
      .nullable(),
    institution: Yup.string()
      .matches(/^(?!.*\b\d+\b)[a-zA-Z0-9]+$/, 'Institution must contain letters and can optionally include numbers, but cannot be just numbers')
      .nullable(),
    location: Yup.string()
      .matches(/^(?!.*\b\d+\b)[a-zA-Z0-9]+$/, 'Location must contain letters and can optionally include numbers, but cannot be just numbers')
      .nullable(),
    phone_number: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, 'Phone Number must be numeric') // Allow empty values but check if provided
  });
  

  const formik = useFormik<UpdateProfile>({
    initialValues: {
      first_name: profile.first_name || '',
      last_name: profile.last_name || '',
      position: profile.position || '',
      institution: profile.institution || '',
      location: profile.location || '',
      phone_number: profile.phone_number || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateProfileApi(values);
        onProfileUpdate({ ...profile, ...values }); // Ensure the full profile is updated

        // Refetch the profile data to ensure the latest information is displayed
        await refetchProfile();

        onClose();
      } catch (error) {
        console.error('Error updating profile:', error);
        MySwal.fire({
          title: 'Error!',
          text: 'There was an error updating the profile.',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (open) {
      formik.setValues(profile);
    }
  }, [open, profile]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Profile</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            id="first_name"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            helperText={formik.errors.first_name} // Show validation errors
            error={Boolean(formik.errors.first_name)}
          />
          <TextField
            margin="dense"
            id="last_name"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            helperText={formik.errors.last_name} // Show validation errors
            error={Boolean(formik.errors.last_name)}
          />
          <TextField
            margin="dense"
            id="position"
            name="position"
            label="Position"
            type="text"
            fullWidth
            variant="outlined"
            value={formik.values.position}
            onChange={formik.handleChange}
            helperText={formik.errors.position} // Show validation errors
            error={Boolean(formik.errors.position)}
          />
          <TextField
            margin="dense"
            id="institution"
            name="institution"
            label="Institution"
            type="text"
            fullWidth
            variant="outlined"
            value={formik.values.institution}
            onChange={formik.handleChange}
            helperText={formik.errors.institution} // Show validation errors
            error={Boolean(formik.errors.institution)}
          />
          <TextField
            margin="dense"
            id="location"
            name="location"
            label="Location"
            type="text"
            fullWidth
            variant="outlined"
            value={formik.values.location}
            onChange={formik.handleChange}
            helperText={formik.errors.location} // Show validation errors
            error={Boolean(formik.errors.location)}
          />
          <TextField
            margin="dense"
            id="phone_number"
            name="phone_number"
            label="Phone Number"
            type="tel" // Use type="tel" to bring up the numeric keyboard and restrict input
            fullWidth
            variant="outlined"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict to numeric input
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            helperText={formik.errors.phone_number} // Show validation errors
            error={Boolean(formik.errors.phone_number)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </form>
      <Backdrop style={{ zIndex: 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default UpdateProfileDialog;
