import  { AxiosResponse, AxiosProgressEvent } from 'axios';

import { apiClient } from './apiClient';
interface FtpMetadataRequest {
  hostname:string
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
}
export const GetProjects = () => {
  return apiClient.get('/summary');
};

export const updateProjectName = async (oldProjectName: string, newProjectName: string) => {
  try {
    const response = await apiClient.post('/edit_project_name', {
      old_project_name: oldProjectName,
      new_project_name: newProjectName,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update project name:', error);
    throw error;
  }
};
export const GetProjectResults = async (projectId: number): Promise<string> => {
  try {
    // Make the GET request to the API
    const response = await apiClient.get(`/results/${projectId}`);
    // Extract the URL from the response
    return response.data.url;
  } catch (error) {
    console.error("Error fetching project results:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
export const multiDeleteProjectapi = (projectNames: string[]) => {
  return apiClient.post(`/delete`, projectNames);
};
export const ShareProjectapi = (data: { project_names: string[], emails: string[] }) => {
  return apiClient.post('/share', data);
};

export const GetShareProjectapi = () => {
  return apiClient.get('/shared');
};

export const CreateProjectapi = (data: { project_names: string, type: string, genome: string }) => {
  return apiClient.post('/share', data);
};

export const checkProjectNameApi = (data: { pipeline: string, genome: string, project_name: string }) => {
  return apiClient.post('/check_project_name', data);
};

export const uploadFilesApi = (
  projectName: string,
  files: File[],
  data: number,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
): Promise<AxiosResponse<any, any>> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
  });

  return apiClient.post(`/files_upload?project_name=${projectName}&data=${data}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
export const getFromGeoApi = (geoCodes: string[], projectName: string) => {
  return apiClient.post('/get_from_geo', {
    geo_codes: geoCodes,
    project_name: projectName,
  });
};
export const uploadUrlsApi = (fileUrls: string[], filedata: number, projectName: string,) => {
  const data = {
    project_name: projectName,
    urls: fileUrls,
    data: filedata
  };

  return apiClient.post('/get-from-drive', data);
};
export const getFtpMetadata = async (ftpDetails: FtpMetadataRequest) => {
  try {
    const response = await apiClient.post('/get-metadata-from-sftp', {
      hostname: ftpDetails.hostname,
      sftp_username: ftpDetails.sftp_username,
      sftp_password: ftpDetails.sftp_password,
      sftp_remote_path: ftpDetails.sftp_remote_path,
    });
  
    if (response.status >= 200 && response.status < 300) {
      console.log('FTP Metadata Response:', response.data);
      // Process the successful response
      return response.data;
    } else {
      console.warn('Received non-2xx status:', response.status);
      // Handle non-2xx status as needed
    }
  } catch (error: any) {
    console.error('Error fetching FTP metadata:', error.response ? error.response.data : error.message);
    throw error;
  }
  
};

export const SubmitFtp = (sftpDetails: FtpMetadataRequest[], projectName: string, combinedFileSize: number) => {
  return apiClient.post(
    `/get-from-sftp?project_name=${projectName}&combined_file_size=${combinedFileSize}`,
    sftpDetails // Directly pass the list here
  );
};




export const getUrlHeaderApi = (fileUrl: string) => {
  return apiClient.post('/get-file-metadata', { url: fileUrl }, {
    headers: {
      'Content-Type': 'application/json'  // Ensure the content type is set to JSON
    }
  });
};

export const getLogApi = (project_name:string) => {  
  return apiClient.get(`/progress/${project_name}`);
};

export const getVnexUrl = (project_id: number) => {  
  return apiClient.get(`/results/${project_id}`);
};
