import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import EmailForm from './EmailForm';
import OTPForm from './OTPForm';
import { startPasswordlessLogin } from '../../services/loginApi';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'error' | 'success' | null>(null);
  const [loading, setLoading] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(60);
  const [canResend, setCanResend] = useState(true);

  const handleEmailSubmit = () => {
    setShowOtpForm(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!canResend) {
      timer = setInterval(() => {
        setResendCooldown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setCanResend(true);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canResend]);

  const handleResendOtp = () => {
    if (canResend) {
      startPasswordlessLogin(email)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'OTP resent',
            text: 'OTP has been resent to your email.',
            timer: 1000,
            showConfirmButton: false,
          });
          setMessage('OTP resent to your email.');
          setMessageType('success');
          setCanResend(false);
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to resend OTP. Please try again later.',
          });
        });
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Wait',
        text: `Please wait ${resendCooldown} seconds before resending the OTP.`,
      });
    }
  };

  const handleBackToEmail = () => {
    setShowOtpForm(false);
    setEmail('');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-neutral-200">
      <div className="relative w-full max-w-lg p-10 bg-white rounded-lg shadow-xl">
        <div className="absolute inset-x-0 top-0 w-full overflow-hidden rounded-t-lg h-72">
          <div className="w-full h-full transform -translate-y-1/2 bg-teal-700 rounded-b-full"></div>
        </div>
        <div className="relative flex justify-center mt-20">
          <div className="flex items-center justify-center w-16 h-16 bg-white rounded-lg shadow-xl">
            <Logo className="w-12 h-12" />
          </div>
        </div>
        {showOtpForm ? (
          <OTPForm
            otp={otp}
            setOtp={setOtp}
            handleBackToEmail={handleBackToEmail}
            handleResendOtp={handleResendOtp}
            email={email}
            loading={loading}
            setLoading={setLoading}
            setMessage={setMessage}
            setMessageType={setMessageType}
          />
        ) : (
          <EmailForm
            email={email}
            loading={loading}
            setEmail={setEmail}
            setLoading={setLoading}
            handleEmailSubmit={handleEmailSubmit}
            setMessage={setMessage}
            setMessageType={setMessageType}
          />
        )}
        {message && (
          <p className={`mt-4 text-center text-lg ${messageType === 'error' ? 'text-red-500' : 'text-teal-500'}`}>
            {message}
          </p>
        )}
        <p className="mt-8 text-sm text-center text-neutral-400">
          &copy; 2024 Nexco Analytics. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Login;
