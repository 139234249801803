import React, { useState } from 'react';

interface Sample {
  fileName: string;
  sampleName: string;
  groupName?: string;
  color?: string;
}

interface NameAndDefineGroupsProps {
  selectedSamples: Sample[];
  setConfiguredSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  setSelectedSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  samples: Sample[];
  configuredSamples: Sample[];
}

const NameAndDefineGroups: React.FC<NameAndDefineGroupsProps> = ({
  selectedSamples,
  setConfiguredSamples,
  setSelectedSamples,
  setSamples,
  samples,
  configuredSamples,
}) => {
  const [groupName, setGroupName] = useState('');
  const [colorOptions, setColorOptions] = useState([
    '#2F855A', // Green
    '#4A5568', // Gray
    '#ED8936', // Orange
    '#3182CE', // Blue
    '#D53F8C', // Pink
    '#805AD5', // Purple
    '#38A169', // Dark Green
    '#ECC94B', // Yellow
    '#DD6B20', // Dark Orange
    '#E53E3E', // Red
  ]);
  const [selectedColor, setSelectedColor] = useState('#2F855A');
  const [error, setError] = useState('');

  const handleCreateGroup = () => {
    if (selectedSamples.length === 0) {
      setError('Please select a sample.');
      return;
    }
    if (groupName.trim() === '') {
      setError('Please enter a group name.');
      return;
    }

    const groupExists = configuredSamples.some(
      (sample) => sample.groupName === groupName,
    );
    if (groupExists) {
      setError('Group name already exists.');
      return;
    }

    const newConfiguredSamples = [
      ...configuredSamples,
      ...selectedSamples.map((sample) => ({
        ...sample,
        groupName,
        color: selectedColor,
      })),
    ];

    const remainingSamples = samples.filter(
      (sample) => !selectedSamples.includes(sample),
    );

    // Update local storage
    const projectname = window.location.pathname.split('/')[2];
    if (projectname) {
      const storedConfiguredSamples = JSON.parse(
        localStorage.getItem(`${projectname}-configured-samples`) || '[]',
      );
      const updatedConfiguredSamples = [
        ...storedConfiguredSamples,
        ...selectedSamples.map((sample) => ({
          ...sample,
          groupName,
          color: selectedColor,
        })),
      ];

      localStorage.setItem(
        `${projectname}-samples`,
        JSON.stringify(remainingSamples),
      );
      localStorage.setItem(
        `${projectname}-configured-samples`,
        JSON.stringify(updatedConfiguredSamples),
      );
    }

    setConfiguredSamples(newConfiguredSamples);
    setSamples(remainingSamples);
    setSelectedSamples([]);
    setGroupName('');

    // Rotate the colors
    setColorOptions((prevColors) => {
      const updatedColors = [...prevColors.slice(1), prevColors[0]];
      setSelectedColor(updatedColors[0]); // Set the next color as selected
      return updatedColors;
    });

    setError('');
  };

  return (
    <div className="mb-4">
      <h2 className="pl-4 mb-2 text-xl font-semibold">Name and Define Groups</h2>
      <div className="p-4 bg-white rounded-lg">
        <div className="flex items-center mb-2 space-x-4">
          <div className="flex items-center w-full space-x-2">
            <input
              type="text"
              placeholder="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className="flex-grow p-2 py-3 border rounded"
            />
            <div className="flex items-center space-x-2">
              {/* Color input box */}
              <div className="relative">
                <input
                  type="text"
                  value="Select Color"
                  readOnly
                  className="w-64 p-3 border border-gray-300 rounded cursor-pointer"
                />
                <div className="absolute flex space-x-2 transform -translate-y-1/2 right-4 top-1/2">
                  {colorOptions.slice(0, 3).map((colorOption, index) => (
                    <div
                      key={index}
                      className="w-8 h-8 rounded-lg cursor-pointer" // Reduced size of color circles
                      style={{
                        backgroundColor: colorOption,
                        border: selectedColor === colorOption ? '2px solid #00BFFF' : '2px solid transparent',
                      }}
                      onClick={() => setSelectedColor(colorOption)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handleCreateGroup}
            className="p-3 font-semibold text-white bg-teal-700 rounded"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '125px',
            }}
          >
            Create Group
          </button>
        </div>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default NameAndDefineGroups;
