import React, { useState, useEffect } from 'react';

interface UploadProgressProps {
  urlUploading: boolean;
  urlProgress: number;
  uploading: boolean;
  progress: number;
}

const UploadProgress: React.FC<UploadProgressProps> = ({
  urlUploading,
  urlProgress,
  uploading,
  progress,
}) => {
  const [estimatedUrlTime, setEstimatedUrlTime] = useState<string>('Calculating...');
  const [estimatedFileTime, setEstimatedFileTime] = useState<string>('Calculating...');

  const calculateEstimatedTime = (progress: number, elapsedTime: number): string => {
    const rate = progress / elapsedTime;
    const remainingTime = (100 - progress) / rate;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = Math.floor(remainingTime % 60);

    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const startTime = Date.now();

    if (urlUploading) {
      const urlInterval = setInterval(() => {
        const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
        setEstimatedUrlTime(calculateEstimatedTime(urlProgress, elapsedTime));
      }, 800); // Update every 800 milliseconds

      return () => clearInterval(urlInterval); // Cleanup interval on unmount
    }
  }, [urlUploading, urlProgress]);

  useEffect(() => {
    const startTime = Date.now();

    if (uploading) {
      const fileInterval = setInterval(() => {
        const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
        setEstimatedFileTime(calculateEstimatedTime(progress, elapsedTime));
      }, 800); // Update every 800 milliseconds

      return () => clearInterval(fileInterval); // Cleanup interval on unmount
    }
  }, [uploading, progress]);

  return (
    <div>
      {urlUploading && (
        <div className="relative pt-1">
          <div className="flex items-center justify-between mb-2">
            <div>
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase bg-blue-600 rounded-full">
                URL Uploading
              </span>
            </div>
            <div className="mt-1">
              <span className="inline-block text-xs font-semibold text-blue-600">
                {urlProgress}% - {estimatedUrlTime} remaining
              </span>
            </div>
          </div>

          <div className="flex h-2 mb-4 overflow-hidden text-xs bg-blue-100 rounded">
            <div
              style={{ width: `${urlProgress}%` }}
              className="flex flex-col justify-center text-center text-white bg-blue-700 shadow-none whitespace-nowrap"
            ></div>
          </div>
        </div>
      )}
      {uploading && (
        <div className="relative pt-1">
          <div className="flex items-center justify-between mb-2">
            <div>
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase bg-teal-600 rounded-full">
                Uploading
              </span>
            </div>
            <div className="mt-1">
              <span className="inline-block text-xs font-semibold text-teal-600">
                {progress}% - {estimatedFileTime} remaining
              </span>
            </div>
          </div>

          <div className="flex h-2 mb-4 overflow-hidden text-xs bg-teal-100 rounded">
            <div
              style={{ width: `${progress}%` }}
              className="flex flex-col justify-center text-center text-white bg-teal-700 shadow-none whitespace-nowrap"
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadProgress;
