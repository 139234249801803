import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import UploadForm from './UploadForm';
import FileUploadSection from './FileUploadSection';
import AdditionalInputs from './AdditionalInputs';
import Swal from 'sweetalert2';
import { getFromGeoApi, SubmitFtp } from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import {
  handleExperimentChange,
  handleGenomeChange,
  handleProjectNameChange,
  checkName,
  uploadFiles,
  handleFileDrop,
  handleDiscard,
  uploadUrls,
} from './uploadFunctions';
import { GetUserSubscription } from '../../services/subscriptionApi';
import { getProfileApi } from '../../services/ProfileApi';
import { useNavigate } from 'react-router-dom';
import UploadProgress from './UploadProgress';
import { useProjectRefresh } from '../../contexts/ProjectRefreshContext';
import { getSubscriptionLimits } from '../../utils/subscriptionLimits'; // Import the function to get limits

type SelectedURLfile = {
  filename: string;
  filesize: number;
  url: string;
};

type FtpDetails = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
  file_name: string;
  file_size: number;
};

const Upload: React.FC = () => {
  const [experiment, setExperiment] = useState<string>('');
  const [genome, setGenome] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [geoCode, setGeoCode] = useState<string>('');
  const [geoCodes, setGeoCodes] = useState<string[]>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ projectName?: string, experiment?: string, genome?: string, selectedFilesOrURLs?: string }>({});
  const [SelectedURLfile, setSelectedURLfile] = useState<SelectedURLfile[]>([]);
  const [ftpDetails, setFtpDetails] = useState<FtpDetails[]>([]); // New state for SFTP details
  const navigate = useNavigate();
  const [urlUploading, setUrlUploading] = useState<boolean>(false);
  const [urlProgress, setUrlProgress] = useState<number>(0);
  const triggerRefresh = useProjectRefresh();
  const SIZE_THRESHOLD_MB = 20;
  const clearError = (field: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  useEffect(() => {
    const checkProjectLimit = async () => {
      setLoading(true);
      
      const [subscriptionResponse, profileResponse] = await Promise.all([GetUserSubscription(), getProfileApi()]);
      const subscription = subscriptionResponse.data;
      const profile = profileResponse.data;
      
      const limits = getSubscriptionLimits(subscription?.item_price_name || 'Free'); // Fetch limits from the utility
      
      if (profile.num_projects >= limits.maxProjects) {
        Swal.fire({
          title: 'Project Limit Reached',
          text: 'You have reached your monthly project limit. Would you like to upgrade your plan?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Upgrade',
          cancelButtonText: 'No, Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/account');
          } else {
            navigate('/');
          }
        });
        return;
      }
      
      setLoading(false);
    };
  
    checkProjectLimit();
  }, [navigate]);

  const checkStorageLimit = async (
    selectedFiles: File[],
    SelectedURLfile: SelectedURLfile[],
    ftpDetails: FtpDetails[]
  ): Promise<boolean> => {
    setLoading(true);
    const totalSelectedFileSizeMB = Math.round(
      selectedFiles.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024) // Converting to MB
    );
    const totalFileSizeMB = totalSelectedFileSizeMB;
    const totalURLFileSizeMB = SelectedURLfile.reduce((acc, file) => acc + file.filesize, 0) / (1024 * 1024);
    const totalFtpFileSizeMB = ftpDetails.reduce((acc, ftp) => acc + ftp.file_size, 0) / (1024 * 1024);
    const combinedTotalFileSizeMB = totalFileSizeMB + totalURLFileSizeMB + totalFtpFileSizeMB;

    const [subscriptionResponse, profileResponse] = await Promise.all([GetUserSubscription(), getProfileApi()]);
    const subscription = subscriptionResponse.data;
    const profile = profileResponse.data;

    const limits = getSubscriptionLimits(subscription?.item_price_name || 'Free'); // Fetch limits from the utility
    const maxStorageMB = limits.maxDataGB * 1024; // Convert GB to MB

    const totalDataMB = profile.total_data;

    if (totalDataMB + combinedTotalFileSizeMB > maxStorageMB) {
      const exceededAmountMB = totalDataMB + combinedTotalFileSizeMB - maxStorageMB;
      Swal.fire({
        title: 'Storage Limit Exceeded',
        text: `You have exceeded your data usage by ${exceededAmountMB} MB. Please upgrade your plan to proceed.`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Upgrade Plan',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
      setLoading(false);
      return false; // Exceeded storage
    }
    setLoading(false);
    return true; // Within storage limit
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (SelectedURLfile.length > 0 || geoCodes.length > 0 || ftpDetails.length > 0) {
      setErrors({
        selectedFilesOrURLs: 'You can only upload one type: File, URL, GEO code, or FTP.',
      });
      return; // Prevent submission
    }

    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);

      const pattern = /^[a-zA-Z0-9_.-]+$/;

      const invalidFiles = newFiles.filter(file => !pattern.test(file.name));
      if (invalidFiles.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File Names',
          text: `The following files have invalid names: ${invalidFiles.map(file => file.name).join(', ')}. Only alphanumerical characters, '-', '.' and '_' are allowed.`,
          width: '350px',
          padding: '1em',
          position: 'top-end',
        });
        return;
      }

      const newSelectedFiles = [...selectedFiles, ...newFiles];
      const withinLimit = await checkStorageLimit(selectedFiles, SelectedURLfile, ftpDetails);
      if (!withinLimit) {
        return;
      }

      setSelectedFiles(newSelectedFiles);
      clearError('selectedFilesOrURLs');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    const newErrors: { projectName?: string, experiment?: string, genome?: string, selectedFilesOrURLs?: string } = {};
    if (!projectName) newErrors.projectName = 'Project name is required';
    if (!experiment) newErrors.experiment = 'Experiment is required';
    if (!genome) newErrors.genome = 'Genome is required';
    if (selectedFiles.length === 0 && SelectedURLfile.length === 0 && geoCodes.length === 0 && ftpDetails.length === 0) {
      newErrors.selectedFilesOrURLs = 'Either rawdata file, GEO code or Link must be uploaded before proceeding';
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length > 0) {
      return;
    }
  
    const withinLimit = await checkStorageLimit(selectedFiles, SelectedURLfile, ftpDetails);
  
    if (!withinLimit) {
      return; // Exit if storage limit is exceeded
    }
  
    try {
      setLoading(true);
  
      const isProjectCreated = await checkName(experiment, genome, projectName, setProjectName);
  
      const totalSelectedFileSizeMB = selectedFiles.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024);
      const totalURLFileSizeMB = SelectedURLfile.reduce((acc, file) => acc + file.filesize, 0);
      const totalFtpFileSizeMB = ftpDetails.reduce((acc, ftp) => acc + ftp.file_size, 0);
      const totalSizeMB = totalSelectedFileSizeMB + totalURLFileSizeMB + totalFtpFileSizeMB;
  
      const uploadPromises = [];
  
      if (isProjectCreated) {
        if (totalSizeMB > SIZE_THRESHOLD_MB) {
          Swal.fire({
            title: 'Your files are being uploaded!',
            text: 'Would you like to go to the projects page or wait here?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Go to Projects',
            cancelButtonText: 'Wait Here',
            allowOutsideClick: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/'); // Navigate to projects page
            }
          });
        }
  
        // Add file upload logic here without showing the popup if the file size is under 20 MB
        if (geoCodes.length > 0) {
          uploadPromises.push(
            getFromGeoApi(geoCodes, projectName).then(response => {
              if (response.status !== 200 && response.status !== 202) {
                throw new Error(response.data?.detail || `Failed to upload GEO data for code: ${geoCodes}`);
              }
            })
          );
        }
  
        if (SelectedURLfile.length > 0) {
          setUrlUploading(true);
          const urlUploadPromise = uploadUrls(projectName, SelectedURLfile)
            .then(result => {
              if (!result.success) {
                throw new Error(result.message);
              }
            })
            .finally(() => {
              setUrlUploading(false);
            });
  
          uploadPromises.push(urlUploadPromise);
        }
  
        if (selectedFiles.length > 0) {
          const fileUploadPromise = uploadFiles(projectName, selectedFiles, setUploading, setProgress)
            .then(result => {
              if (!result.success) {
                throw new Error(result.message);
              }
            });
  
          uploadPromises.push(fileUploadPromise);
        }
  
        if (ftpDetails.length > 0) {
          const combinedFtpFileSizeMB = ftpDetails.reduce((acc, ftp) => acc + ftp.file_size, 0) / (1024 * 1024);
  
          uploadPromises.push(
            SubmitFtp(ftpDetails, projectName, combinedFtpFileSizeMB).then(response => {
              if (response.status !== 202) {
                throw new Error(response.data?.detail || `Failed to upload SFTP files.`);
              }
            })
          );
        }
  
        const results = await Promise.allSettled(uploadPromises);
  
        const failedUploads = results.filter(result => result.status === 'rejected');
  
        if (failedUploads.length > 0) {
          const failedMessages = failedUploads.map(result => (result as any).reason.message).join(', ');
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Some uploads failed: ${failedMessages}`,
            width: '350px',
            padding: '1em',
            position: 'top-end',
          });
        } else {
          triggerRefresh();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'All files, URLs, GEO codes, and FTP entries uploaded successfully!',
            showConfirmButton: true,
            confirmButtonText: 'Go Configure',
            width: '300px',
            padding: '1em',
            position: 'top-end',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/configure/${projectName}`);
            }
          });
  
          setSelectedURLfile([]);
          setSelectedFiles([]);
          setGeoCodes([]);
          setFtpDetails([]);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error occurred during the project creation or upload process',
        width: '500px',
        padding: '1.5em',
      });
    } finally {
      setLoading(false);
      setUploading(false);
      setUrlUploading(false);
    }
  };
  
  useEffect(() => {
    const dropZone = document.getElementById('drop-zone');

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      handleFileDrop(event, setSelectedFiles);
    };

    if (dropZone) {
      dropZone.addEventListener('dragover', (event) => {
        event.preventDefault();
      });

      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', (event) => {
          event.preventDefault();
        });

        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  return (
    <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md max-w-7xl">
      <div className="flex items-center justify-between mb-6">
        <button
          className="flex items-center text-lg font-bold text-gray-700"
          onClick={() => { navigate('/') }}
        >
          <ArrowBackIcon className="mt-0.5 mr-2" /> Create Project
        </button>
        <button
  className="flex items-center px-4 py-2 text-blue-700 bg-white border border-blue-400 rounded hover:bg-blue-100"
  onClick={() => handleDiscard(setExperiment, setGenome, setProjectName, setSelectedFiles, setProgress, setGeoCodes, setFileUrl)}
>
  <CloseIcon className="mr-2" /> Reset
</button>

      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <UploadForm
          projectName={projectName}
          experiment={experiment}
          genome={genome}
          handleProjectNameChange={(event) => handleProjectNameChange(event, setProjectName)}
          handleExperimentChange={(event) => handleExperimentChange(event, setExperiment)}
          handleGenomeChange={(event) => handleGenomeChange(event, setGenome)}
          clearError={clearError}
          errors={errors}
        />

        <p className="mb-6 text-gray-500">
          Other parameters (such as the data being single or paired-end or if the sequencing was stranded) will be automatically detected.
        </p>

        <h6 className="mb-2 text-xl font-semibold">Upload Data</h6>

        <div id="drop-zone">
          {loading && (
            <div className="flex items-center justify-center">
              <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
          )}
          {!loading && (
            <FileUploadSection handleFileSelect={handleFileSelect} />
          )}
        </div>

        <AdditionalInputs
          geoCode={geoCode}
          setGeoCode={setGeoCode}
          geoCodes={geoCodes}
          setGeoCodes={setGeoCodes}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          SelectedURLfile={SelectedURLfile}
          setSelectedURLfile={setSelectedURLfile}
          ftpDetails={ftpDetails} // Pass ftpDetails
          setFtpDetails={setFtpDetails} // Pass setFtpDetails
          clearError={clearError}
          setErrors={setErrors}
        />
        {errors.selectedFilesOrURLs && <p className="text-red-600">{errors.selectedFilesOrURLs}</p>}
        <UploadProgress
          urlUploading={urlUploading}
          urlProgress={urlProgress}
          uploading={uploading}
          progress={progress}
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex justify-end w-full">
            <button
              type="submit"
              className="px-4 py-2 text-white bg-teal-700 rounded hover:bg-teal-800"
              disabled={uploading}
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Upload;
