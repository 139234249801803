import React, { useState, useEffect } from 'react';
import ProjectTable from './ProjectTable';
import ShareProject from './ShareProject';
import DeleteProject from './DeleteProject';
import { Project } from '../../interface/project';
import Select from 'react-select';
import { getDateRange, customStyles } from '../../utils/projects';
import {
  GetProjects,
  ShareProjectapi,
  getVnexUrl,
  multiDeleteProjectapi,
} from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetUserSubscription } from '../../services/subscriptionApi';
import { useProjectRefresh } from '../../contexts/ProjectRefreshContext';
import { getProfileApi } from '../../services/ProfileApi';
import { getSubscriptionLimits } from '../../utils/subscriptionLimits'; // Import the function

interface ProjectsPageProps {
  projects?: Project[];
}

interface UserSubscription {
  id: string;
  status: string;
  item_price_id: string;
  item_price_name: string;
  item_price_amount: number;
  next_billing_at: number;
}

interface UserProfile {
  num_projects: number;
  // Add other properties of the Profile object here
}

const ProjectsPage: React.FC<ProjectsPageProps> = () => {
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [dateFilter, setDateFilter] = useState<string | null>(null);
  const [experimentFilter, setExperimentFilter] = useState<string | null>(null);
  const [keyword, setKeyword] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedProjectNames, setSelectedProjectNames] = useState<string[]>(
    [],
  );
  const [Profile, setProfile] = useState<UserProfile | null>(null);
  const [genomeFilter, setGenomeFilter] = useState<string | null>(null);
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<UserSubscription | null>(null);
  const navigate = useNavigate();
  const triggerRefresh = useProjectRefresh();

  const handleDeleteProject = (names: string[]) => {
    setSelectedProjectNames(names);
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [subscriptionResponse, profileResponse] = await Promise.all([
          GetUserSubscription(),
          getProfileApi(),
        ]);

        setSubscription(subscriptionResponse.data);
        setProfile(profileResponse.data);
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchUserData();
  }, []);

  const confirmDeleteProjects = () => {
    if (selectedProjectNames.length > 0) {
      multiDeleteProjectapi(selectedProjectNames)
        .then(() => {
          toast.success('Selected projects have been deleted.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setUserProjects((prevProjects) =>
            prevProjects.filter(
              (project) => !selectedProjectNames.includes(project.name)
            )
          );
        })
        .catch((error) => {
          toast.error('There was an error deleting the selected projects.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.error('Error deleting projects:', error);
        });
      setSelectedProjects([]);
      setIsDeleteModalOpen(false);
    }
  };

  const handleShareProject = (projectNames: string | string[]) => {
    if (!subscription || subscription.item_price_name === 'Free') {
      Swal.fire({
        title: 'Upgrade Required',
        text: 'You need to have a subscription to share projects. Would you like to upgrade?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upgrade',
        cancelButtonText: 'No, cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
      return;
    }

    setSelectedProjects(
      Array.isArray(projectNames) ? projectNames : [projectNames],
    );
    setIsShareModalOpen(true);
  };

  const confirmShareProject = (project_names: string[], emails: string[]) => {
    ShareProjectapi({ project_names, emails })
      .then((response) => {
        const { message } = response.data;
        toast.success(`Project shared successfully`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        let errorMessage = 'There was an error sharing the projects.';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('Error sharing projects:', error);
      });
  };

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const projectsData = await GetProjects();
      const projectsArray = Array.isArray(projectsData.data)
        ? projectsData.data
        : [];
      setUserProjects(projectsArray);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setUserProjects([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [triggerRefresh]);

  const handleNameChange = (oldName: string, newName: string) => {
    setUserProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.name === oldName ? { ...project, name: newName } : project,
      ),
    );
  };

  // Get project limit from the subscriptionLimits utility
  const projectLimit = () => {
    const limits = getSubscriptionLimits(subscription?.item_price_name || 'Free');
    return limits.maxProjects;
    console.log(limits);
    
  };

  const handleCreateProject = () => {
    if (Profile && Profile.num_projects >= projectLimit()) {
      Swal.fire({
        title: 'Project Limit Reached',
        text: 'You have reached your monthly project limit. Would you like to upgrade your plan?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upgrade',
        cancelButtonText: 'No, cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
    } else {
      navigate('/upload');
    }
  };

  const { startDate, endDate } = getDateRange(dateFilter || '');

  const filteredProjects = userProjects.filter((project) => {
    const projectDate = new Date(project.dateAdded);
    const keywordLower = keyword.toLowerCase();
    const projectNameLower = project.name.toLowerCase();

    return (
      (statusFilter === null || project.status === statusFilter) &&
      (dateFilter === null ||
        (projectDate >= startDate && projectDate <= endDate)) &&
      (experimentFilter === null || project.type === experimentFilter) &&
      (genomeFilter === null || project.genome === genomeFilter) &&
      (keyword === '' || projectNameLower.startsWith(keywordLower))
    );
  });

  const statusOptions = [
    { value: 'Ready', label: 'View' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Configured', label: 'In Progress' },
    { value: 'Uploaded', label: 'Configure' },
    { value: 'Not Uploaded', label: 'Uploading' },
  ];

  const dateOptions = [
    { value: 'Today', label: 'Today' },
    { value: 'Past 7 Days', label: 'Past 7 Days' },
    { value: 'Past Month', label: 'Past Month' },
    { value: 'Past Year', label: 'Past Year' },
  ];

  const experimentOptions = [{ value: 'RNAseq', label: 'RNAseq' }];

  const genomeOptions = [
    { value: 'hg38', label: 'hg38' },
    { value: 'hg19', label: 'hg19' },
    { value: 'mm10', label: 'mm10' },
    { value: 'mm9', label: 'mm9' },
  ];

  const availableStatuses = Array.from(
    new Set(userProjects.map((p) => p.status)),
  );
  const filteredStatusOptions = statusOptions.filter((option) =>
    availableStatuses.includes(option.value),
  );

  const availableDates = dateOptions.filter((option) =>
    userProjects.some((project) => {
      const projectDate = new Date(project.dateAdded);
      const { startDate, endDate } = getDateRange(option.value);
      return projectDate >= startDate && projectDate <= endDate;
    }),
  );

  const availableGenomes = Array.from(new Set(userProjects.map((p) => p.genome)));
  const filteredGenomeOptions = genomeOptions.filter((option) =>
    availableGenomes.includes(option.value),
  );

  return (
    <div className="container mx-auto">
      <ToastContainer />
      <div className="p-4 bg-white rounded-lg shadow-lg shadow-gray-500/50">
        <div className="flex items-center justify-between mb-6">
          <h3 className="ml-3 text-2xl font-bold text-gray-800">My Projects</h3>
          <div className="flex items-center space-x-2">
          <button
  className={`px-3 py-3 pt-2 text-white bg-teal-700 rounded-md ${!subscription || !Profile ? 'cursor-wait opacity-50' : 'hover:bg-teal-800'}`}
  onClick={handleCreateProject}
  disabled={!subscription || !Profile} // Disable the button until subscription is fetched
>
  <span className="text-xl">+</span> New Project
</button>

          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center ml-2 space-x-4">
            <input
              type="text"
              placeholder="Type Keyword here"
              className="px-5 py-3 text-gray-600 border border-gray-300 rounded-md"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            {selectedProjects.length > 0 && (
              <>
                <button
                  className="w-24 px-5 py-3 text-white bg-teal-700 rounded-md hover:bg-teal-800"
                  onClick={() => handleShareProject(selectedProjects)}
                >
                  Share
                </button>
                <button
                  className="w-24 px-5 py-3 text-white bg-orange-600 rounded-md hover:bg-orange-700"
                  onClick={() => handleDeleteProject(selectedProjects)}
                >
                  Delete
                </button>
              </>
            )}
          </div>
          <div className="flex space-x-4">
            <div className="w-46">
              <Select
                options={filteredStatusOptions}
                value={filteredStatusOptions.find(
                  (option) => option.value === statusFilter,
                )}
                onChange={(selectedOption) =>
                  setStatusFilter(selectedOption ? selectedOption.value : null)
                }
                placeholder="Status"
                isClearable
                styles={customStyles}
              />
            </div>
            <div className="w-46">
              <Select
                options={availableDates}
                value={availableDates.find((option) => option.value === dateFilter)}
                onChange={(selectedOption) =>
                  setDateFilter(selectedOption ? selectedOption.value : null)
                }
                placeholder="Date Added"
                isClearable
                styles={customStyles}
              />
            </div>
            <div className="w-46">
              <Select
                options={experimentOptions}
                value={experimentOptions.find(
                  (option) => option.value === experimentFilter,
                )}
                onChange={(selectedOption) =>
                  setExperimentFilter(
                    selectedOption ? selectedOption.value : null,
                  )
                }
                placeholder="Experiment"
                isClearable
                styles={customStyles}
              />
            </div>
            <div className="w-46">
              <Select
                options={filteredGenomeOptions}
                value={filteredGenomeOptions.find(
                  (option) => option.value === genomeFilter,
                )}
                onChange={(selectedOption) =>
                  setGenomeFilter(selectedOption ? selectedOption.value : null)
                }
                placeholder="Genome"
                isClearable
                styles={customStyles}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-64">
            <ClipLoader size={50} color={'#123abc'} loading={isLoading} />
          </div>
        ) : filteredProjects.length > 0 ? (
          <ProjectTable
            projects={filteredProjects}
            selectedProjects={selectedProjects}
            handleDeleteProject={handleDeleteProject}
            handleShareProject={handleShareProject}
            handleSelectProject={setSelectedProjects}
            handleNameChange={handleNameChange}
          />
        ) : (
          <p>No projects created</p>
        )}
      </div>
      <ShareProject
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        onShare={confirmShareProject}
        projectNames={selectedProjects}
      />

      <DeleteProject
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={confirmDeleteProjects}
        projectCount={selectedProjectNames.length}
      />
    </div>
  );
};

export default ProjectsPage;
