import React from 'react';

interface FileUploadSectionProps {
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CloudUploadIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
    <path d="M12 12v9" />
    <path d="m16 16-4-4-4 4" />
  </svg>
);

const FileUploadSection: React.FC<FileUploadSectionProps> = ({ handleFileSelect }) => {
  return (
    <div className="p-10 mb-1 text-center border-2 border-gray-300 border-dashed cursor-pointer" id="drop-zone" onClick={() => document.getElementById('file-input')?.click()}>
      <CloudUploadIcon className="w-12 h-12 mx-auto mb-4 text-teal-600" />
      <p className="pb-2 mb-4 font-semibold text-teal-800">Drag & Drop your file(s) to start uploading</p>

      {/* Dotted line with "OR" centered */}
      <div className="flex items-center justify-center mb-4">
        <div className="flex-grow border border-gray-300 border-dashed"></div>
        <span className="px-4 font-semibold text-gray-500">OR</span>
        <div className="flex-grow border border-gray-300 border-dashed"></div>
      </div>

      <input
        id="file-input"
        type="file"
        name="file-input"
        multiple
        className="hidden"
        onChange={handleFileSelect}
      />
      <button type="button" className="px-4 py-2 mt-4 font-semibold text-teal-800 bg-teal-800 rounded-lg bg-opacity-10 hover:bg-opacity-20">
        Choose File
      </button>
    </div>
  );
};

export default FileUploadSection;
