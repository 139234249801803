import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { sendBugReport } from "../../services/emailing";
import FileUploadSection from "../upload/FileUploadSection";
import { FormControl, InputLabel, MenuItem, Select, TextField, IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useProfile } from "../../contexts/ProfileContext";

function Report(): JSX.Element {
  const navigate = useNavigate();
  const { profile } = useProfile();

  const [email, setEmail] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [steps, setSteps] = useState<string>("");
  const [severity, setSeverity] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);
  const [emailError, setEmailError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (profile && profile.email) {
      setEmail(profile.email);
    }
  }, [profile]);

  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    if (!validateEmail(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement> | File[]) => {
    if (Array.isArray(event)) {
      setFiles((prevFiles) => [...prevFiles, ...event]);
    } else if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (emailError) {
      Swal.fire({
        title: "Error!",
        text: "Please correct the errors before submitting.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("description", description);
    formData.append("steps", steps);
    formData.append("severity", severity);
    
    files.forEach((file) => {
      formData.append("screenshots", file);
    });

    try {
      await sendBugReport(formData);
     navigate('/');
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to send bug report. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = () => {
    setEmail("");
    setDescription("");
    setSteps("");
    setSeverity("");
    setFiles([]);
    setEmailError("");
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    handleFileSelect(droppedFiles);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="relative w-11/12 p-8 mx-auto bg-white rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-8">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-lg font-bold text-gray-700 hover:text-gray-900"
        >
          <ArrowBackIcon className="mr-2" /> Bug Report
        </button>

        <button
  onClick={handleDiscard}
  className="px-4 py-2 text-sm font-bold text-blue-700 border border-blue-500 rounded-lg hover:bg-blue-100"
>
  <span className="flex items-center">
    <CloseIcon className="mr-2" /> Reset
  </span>
</button>

      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <TextField
            label="Email"
            type="email"
            id="email"
            fullWidth
            error={!!emailError}
            helperText={emailError}
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
          <div>
            <TextField
              label="Brief description of the issue"
              id="description"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe the bug you encountered"
              required
            />
          </div>
          <div>
            <TextField
              label="Steps to reproduce the issue"
              id="steps"
              fullWidth
              multiline
              rows={4}
              value={steps}
              onChange={(e) => setSteps(e.target.value)}
              placeholder="Steps to reproduce the bug"
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <FormControl fullWidth>
            <InputLabel id="severity-label">Severity of the issue</InputLabel>
            <Select
              labelId="severity-label"
              id="severity"
              value={severity}
              label="Severity of the issue"
              onChange={(e) => setSeverity(e.target.value as string)}
              required
            >
              <MenuItem value="">
                <em>Select Severity</em>
              </MenuItem>
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
              <MenuItem value="critical">Critical</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="mb-6" onDrop={handleDrop} onDragOver={handleDragOver}>
          <label
            htmlFor="screenshot"
            className="block font-semibold text-gray-700"
          >
            Screenshot of the issue
          </label>
          <FileUploadSection handleFileSelect={handleFileSelect} />
        </div>

        {files.length > 0 && (
          <div className="grid grid-cols-2 gap-4 mb-6 md:grid-cols-4">
            {files.map((file, index) => (
              <div key={index} className="relative w-full h-32 overflow-hidden border border-gray-300 rounded-lg">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`screenshot-${index}`}
                  className="object-cover w-full h-full"
                />
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => handleRemoveFile(index)}
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        )}
        <div className="flex items-center justify-end mt-8">
          <button
            type="submit"
            disabled={loading}
            className={`text-white font-bold py-2 px-4 rounded ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-teal-700 hover:bg-teal-800"
            }`}
          >
            {loading ? (
              <div className="flex items-center">
                <CircularProgress size={20} color="inherit" />
                <span className="ml-2">Submitting...</span>
              </div>
            ) : (
              "Submit Bug Report"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Report;
