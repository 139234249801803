import { apiClient } from './apiClient';
import Cookies from 'js-cookie';

export const startPasswordlessLogin = (email: string) => {
  return apiClient.post(`/auth/passwordless/start?email=${email}`, { email });
};

export const verifyOtp = async (email: string, otp: string) => {
  try {
    const response = await apiClient.post(`/auth/passwordless/verify-otp?email=${email}&otp=${otp}`, { email });
    const { access_token, refresh_token } = response.data;
    // Store tokens in cookies with Bearer prefix
    Cookies.set('access_token', `Bearer ${access_token}`, { expires: 1, path: '/' });
    Cookies.set('refresh_token', refresh_token, { expires: 7, path: '/' });
    return response.data;
  } catch (error) {
    throw error;
  }
};
