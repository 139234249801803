import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Swal from 'sweetalert2';
import { getUrlHeaderApi, getFtpMetadata } from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import { Tooltip } from '@mui/material';
import { SiGoogledrive, SiMicrosoftonedrive } from 'react-icons/si';
import { FaCloudUploadAlt } from 'react-icons/fa';
import FtpModal from './FtpModal';
type SelectedURLfile = {
  filename: string;
  filesize: number;
  url: string;
};

type FtpMetadataRequest = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
};

type FtpDetails = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
  file_name: string;
  file_size: number;
};

interface AdditionalInputsProps {
  geoCode: string;
  setGeoCode: (geoCode: string) => void;
  geoCodes: string[];
  setGeoCodes: (geoCodes: (prevGeoCodes: string[]) => string[]) => void;
  fileUrl: string;
  setFileUrl: (fileUrl: string) => void;
  selectedFiles: File[];
  setSelectedFiles: (files: File[]) => void;
  SelectedURLfile: SelectedURLfile[];
  setSelectedURLfile: React.Dispatch<React.SetStateAction<SelectedURLfile[]>>;
  ftpDetails: FtpDetails[];
  setFtpDetails: React.Dispatch<React.SetStateAction<FtpDetails[]>>;
  clearError: (field: string) => void;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

const serviceOptions = [
  { name: 'Google Drive', icon: <SiGoogledrive size={24} />, color: 'text-blue-600', tooltip: 'Make sure the file is public on Google Drive.' },
  { name: 'OneDrive', icon: <SiMicrosoftonedrive size={24} />, color: 'text-blue-800', tooltip: 'Make sure the file is public on OneDrive.' },
  { name: 'FTP', icon: <FaCloudUploadAlt size={24} />, color: 'text-green-600', tooltip: 'Ensure your FTP server link is accessible.' },
];

const AdditionalInputs: React.FC<AdditionalInputsProps> = ({
  geoCode,
  setGeoCode,
  geoCodes,
  setGeoCodes,
  fileUrl,
  setFileUrl,
  selectedFiles,
  setSelectedFiles,
  SelectedURLfile,
  ftpDetails,
  setFtpDetails,
  setSelectedURLfile,
  clearError,
  setErrors,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<string>('Google Drive');
  const [isFtpModalOpen, setIsFtpModalOpen] = useState<boolean>(false);
  const [hostname, setHostname] = useState<string>('');

  const handleGeoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeoCode(event.target.value);
  };

  const handleFileUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileUrl(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.currentTarget.name === 'geoCode') {
        handleGeoCodeSubmit();
      } else {
        handleFileUrlSubmit();
      }
    }
  };

  const handleServiceChange = (service: string) => {
    setSelectedService(service);
    if (service === 'FTP') {
      handleFileUrlSubmit();
    }
  };

  const removeGeoCode = (index: number) => {
    setGeoCodes((prevGeoCodes: string[]) => prevGeoCodes.filter((_, i) => i !== index));
  };

  const removeFtpDetail = (index: number) => {
    setFtpDetails((prevDetails) => prevDetails.filter((_, i) => i !== index));
  };

  const handleGeoCodeSubmit = () => {
    if (selectedFiles.length > 0 || SelectedURLfile.length > 0 || ftpDetails.length > 0) {
        setErrors({
            selectedFilesOrURLs: 'You can only upload one type: File, URL, GEO code, or FTP.',
        });
        return; // Prevent submission
    }

    if (geoCode) {
        setGeoCodes((prevGeoCodes: string[]) => [...prevGeoCodes, geoCode]);
        setGeoCode('');
        clearError('selectedFilesOrURLs');
    }
};

const handleFileUrlSubmit = async () => {
    if (selectedFiles.length > 0 || geoCodes.length > 0 || ftpDetails.length > 0) {
        setErrors({
            selectedFilesOrURLs: 'You can only upload one type: File, URL, GEO code, or FTP.',
        });
        return; // Prevent submission
    }

    if (loading) return;

    setLoading(true);
    try {
        const response = await getUrlHeaderApi(fileUrl);

        if (response.data.hostname) {
            setHostname(response.data.hostname); // Store hostname in the state
            setIsFtpModalOpen(true); // Open the FTP modal
        } else {
            const { filename, file_size } = response.data;

            const newFile: SelectedURLfile = {
                filename,
                filesize: parseFloat((file_size / (1024 * 1024)).toFixed(2)), // Convert bytes to MB and format to 2 decimal places
                url: fileUrl,
            };

            setSelectedURLfile((prevFiles: SelectedURLfile[]) => [...prevFiles, newFile]);
            setFileUrl('');
          
        }
    } catch (error) {
        console.error('Error fetching file metadata:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch file metadata. Please check the URL and try again.',
        });
    } finally {
        setLoading(false);
        clearError('selectedFilesOrURLs');
    }
};

const handleFtpSubmit = async (ftpDetails: FtpMetadataRequest) => {
    if (selectedFiles.length > 0 || SelectedURLfile.length > 0 || geoCodes.length > 0) {
        setErrors({
            selectedFilesOrURLs: 'You can only upload one type: File, URL, GEO code, or FTP.',
        });
        return; // Prevent submission
    }

    try {
        const metadata = await getFtpMetadata(ftpDetails);

        let newFtpDetails: FtpDetails[] = [];

        if (Array.isArray(metadata.file_data)) {
            // Handle multiple files
            newFtpDetails = metadata.file_data.map((file: any) => ({
                hostname: ftpDetails.hostname,
                sftp_username: ftpDetails.sftp_username,
                sftp_password: ftpDetails.sftp_password,
                sftp_remote_path: `${ftpDetails.sftp_remote_path}/${file.file_name}`,
                file_name: file.file_name,
                file_size: parseFloat((file.file_size).toFixed(2)), // Convert bytes to MB and format to 2 decimal places
            }));
        } else {
            // Handle a single file
            newFtpDetails = [{
                hostname: ftpDetails.hostname,
                sftp_username: ftpDetails.sftp_username,
                sftp_password: ftpDetails.sftp_password,
                sftp_remote_path: ftpDetails.sftp_remote_path,
                file_name: metadata.file_name,
                file_size: parseFloat((metadata.file_size).toFixed(2)), // Convert bytes to MB and format to 2 decimal places
            }];
        }

        // Update the FTP details in the main form without triggering the error validation
        setFtpDetails((prevDetails) => [...prevDetails, ...newFtpDetails]);

        setIsFtpModalOpen(false); // Close the modal
        clearError('selectedFilesOrURLs'); // Clear error after adding FTP details
    } catch (error) {
        console.error('Error fetching FTP metadata:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to retrieve FTP metadata. Please check your details and try again.',
        });
    }
};

  return (
    <div>
      <p className="pb-4 mb-5 text-center text-gray-500">OR</p>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="relative">
          <input
            type="text"
            name="geoCode"
            placeholder="Enter GEO code e.g. GSE182465"
            className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
            value={geoCode}
            onChange={handleGeoCodeChange}
            onKeyPress={handleKeyPress}
          />
          {geoCode && (
            <CheckCircleIcon
              onClick={handleGeoCodeSubmit}
              className="absolute text-green-500 transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
            />
          )}
        </div>
        <div className="relative">
          <input
            type="text"
            name="fileUrl"
            placeholder="Insert File Link or URL"
            className="w-full px-4 py-3 border border-gray-300 rounded pr-28 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
            value={fileUrl}
            onChange={handleFileUrlChange}
            onKeyPress={handleKeyPress}
            disabled={loading}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-3">
            {serviceOptions.map(({ name, icon, color, tooltip }) => (
              <Tooltip title={tooltip} arrow key={name}>
                <div
                  onClick={() => handleServiceChange(name)}
                  className={`cursor-pointer ${selectedService === name ? color : 'text-gray-400'}`}
                >
                  {icon}
                </div>
              </Tooltip>
            ))}
          </div>
          {loading && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ClipLoader size={20} color={"#123abc"} loading={loading} />
            </div>
          )}
        </div>
      </div>

      {(selectedFiles.length > 0 || SelectedURLfile.length > 0 || geoCodes.length > 0 || ftpDetails.length > 0) && (
        <div className="mt-4">
          <ul className="p-0 space-y-2 overflow-y-auto list-none max-h-56">
            {geoCodes.map((code, index) => (
              <li key={index} className="flex items-center justify-between p-2 mb-2 border border-gray-300 rounded-lg">
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div><span className='font-semibold text-gray-800'>{code}</span></div>
                  </div>
                </div>
                <button
                  onClick={() => removeGeoCode(index)}
                  className="p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700"
                >
                  <ClearIcon style={{ fontSize: '1.3rem' }} />
                </button>
              </li>
            ))}

            {selectedFiles.map((file, index) => (
              <li key={index} className="flex items-center justify-between p-2 mb-2 border border-gray-300 rounded-lg">
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div><span className='font-semibold text-gray-800'>{file.name}</span></div>
                    <div><span className='text-gray-800'>{(file.size / (1024 * 1024)).toFixed(2)} MB</span></div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    const newFiles = [...selectedFiles];
                    newFiles.splice(index, 1);
                    setSelectedFiles(newFiles);
                  }}
                  className="p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700"
                >
                  <ClearIcon style={{ fontSize: '1.3rem' }} />
                </button>
              </li>
            ))}

            {SelectedURLfile.map((file, index) => (
              <li key={index} className="flex items-center justify-between p-2 mb-2 border border-gray-300 rounded-lg">
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div><span className='font-semibold text-gray-800'>{file.filename}</span></div>
                    <div><span className='text-gray-800'>{(file.filesize).toFixed(2)} MB</span></div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    const newFiles = [...SelectedURLfile];
                    newFiles.splice(index, 1);
                    setSelectedURLfile(newFiles);
                  }}
                  className="p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700"
                >
                  <ClearIcon style={{ fontSize: '1.3rem' }} />
                </button>
              </li>
            ))}

            {ftpDetails.map((ftp, index) => (
              <li key={index} className="flex items-center justify-between p-2 mb-2 border border-gray-300 rounded-lg">
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div><span className='font-semibold text-gray-800'>{ftp.file_name}</span></div>
                    <div><span className='text-gray-800'>{ftp.file_size} MB</span></div>
                  </div>
                </div>
                <button
                  onClick={() => removeFtpDetail(index)}
                  className="p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700"
                >
                  <ClearIcon style={{ fontSize: '1.3rem' }} />
                </button>
              </li>
            ))}

          </ul>
        </div>
      )}

<p className="mb-6 text-gray-500">
  Allowed file extensions: fastq.gz, fq.gz, csv. Use a fast and stable internet connection.{" "}
  <a href="/raw_counts.csv" download className="text-teal-700">
    Download Count Table Example.
  </a>
</p>


      <FtpModal
        isOpen={isFtpModalOpen}
        onClose={() => setIsFtpModalOpen(false)}
        onSubmit={handleFtpSubmit}
        hostname={hostname} // Pass hostname to the modal
      />
    </div>
  );
};

export default AdditionalInputs;
