import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SamplesToBeConfigured from './SamplesToBeConfigured';
import NameAndDefineGroups from './NameAndDefineGroups';
import ConfiguredSamples from './ConfiguredSamples';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Swal from 'sweetalert2';
import { SubmitGroups } from '../../services/ConfigApi';
import ConfigureTutorialModal from './ConfigureTutorialModal';
import withReactContent from 'sweetalert2-react-content';
interface Sample {
  fileName: string;
  sampleName: string;
  groupName?: string;
  color?: string;
  associatedFiles?: string[]; // Only for FASTQ data
}

interface Params extends Record<string, string | undefined> {
  projectname?: string;
}
const CompactSwal = withReactContent(Swal.mixin({
  customClass: {
    popup: 'p-1 text-sm', // Reduced padding for the popup
    title: 'text-base font-semibold', // Adjust the title font size and weight
    confirmButton: 'py-1 px-3 text-sm', // Adjust button padding and text size
    cancelButton: 'py-1 px-3 text-sm',
  },
  buttonsStyling: true,
  width: 'auto', // Adjust the width to be more compact
}));
const Configure: React.FC = () => {
  const { projectname } = useParams<Params>();
  const navigate = useNavigate(); // Use navigate for redirection

  const initialModalState = localStorage.getItem('showTutorial') !== 'false';
  const [isModalOpen, setIsModalOpen] = useState<boolean>(initialModalState);

  const [selectedSamples, setSelectedSamples] = useState<Sample[]>([]);
  const [configuredSamples, setConfiguredSamples] = useState<Sample[]>([]);
  const [samples, setSamples] = useState<Sample[]>([]);
  const [error, setError] = useState<string | null>(null); // Error state

  useEffect(() => {
    if (projectname) {
      const storedSamples = localStorage.getItem(`${projectname}-samples`);
      const storedConfiguredSamples = localStorage.getItem(`${projectname}-configured-samples`);
      if (storedSamples && storedSamples !== '[]') {
        setSamples(JSON.parse(storedSamples));
      }

      if (storedConfiguredSamples && storedConfiguredSamples !== '[]') {
        setConfiguredSamples(JSON.parse(storedConfiguredSamples));
      }
    }
  }, [projectname]);

  useEffect(() => {
    if (projectname) {
      localStorage.setItem(`${projectname}-samples`, JSON.stringify(samples));
      localStorage.setItem(`${projectname}-configured-samples`, JSON.stringify(configuredSamples));
    }
  }, [samples, configuredSamples, projectname]);

  const handleSaveConfiguration = async () => {
    const uniqueGroups = Array.from(new Set(configuredSamples.map(sample => sample.groupName).filter(Boolean)));

    if (uniqueGroups.length < 2) {
        setError('Please configure at least two groups before proceeding.');
        return;
    }

    setError(null);

    if (projectname) {
        const formattedData: string[][] = [];

        configuredSamples.forEach(sample => {
            if (sample.associatedFiles && sample.associatedFiles.length > 0) {
                sample.associatedFiles.forEach(associatedFile => {
                    formattedData.push([
                        associatedFile,
                        sample.sampleName,
                        sample.groupName || '',
                        sample.color || '',
                        '', // Blank field 1
                        ''  // Blank field 2
                    ]);
                });
            } else {
                formattedData.push([
                    sample.fileName,
                    sample.sampleName,
                    sample.groupName || '',
                    sample.color || '',
                    '', // Blank field 1
                    ''  // Blank field 2
                ]);
            }
        });

        try {
            const response = await SubmitGroups(projectname, JSON.stringify(formattedData));

                const uniqueGroupsData = uniqueGroups.map(groupName => {
                    const sample = configuredSamples.find(sample => sample.groupName === groupName);
                    return { groupName: sample?.groupName, color: sample?.color };
                });

                localStorage.setItem(`${projectname}-configured-groups`, JSON.stringify(uniqueGroupsData));
                navigate(`/compare/${projectname}`);
            
        } catch (error) {
            console.error('Error saving configuration:', error);
            CompactSwal.fire({
                title: 'Error!',
                text: 'There was an error saving the configuration.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
};

  const handleDiscard = () => {
    if (projectname) {
      const allConfiguredSamples = [...configuredSamples, ...samples];
      localStorage.setItem(`${projectname}-samples`, JSON.stringify(allConfiguredSamples));
      localStorage.removeItem(`${projectname}-configured-samples`);

      setSamples(allConfiguredSamples);
      setConfiguredSamples([]);
    }
  };


  const handleMoveToExistingGroup = (sample: Sample) => {
    // Get unique group names from configured samples
    const groupNames = Array.from(new Set(
      configuredSamples
        .filter(sample => sample.groupName)
        .map(sample => sample.groupName)
    )) as string[];
  
    // Show a selection popup to choose an existing group
    Swal.fire({
      title: 'Move to Existing Group',
      input: 'select',
      inputOptions: groupNames.reduce((options, groupName) => {
        options[groupName] = groupName; // Populate the select options with group names
        return options;
      }, {} as Record<string, string>),
      inputPlaceholder: 'Select a group',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed && result.value) {
        const selectedGroupName = result.value;
        const groupColor = configuredSamples.find(sample => sample.groupName === selectedGroupName)?.color;
  
        if (selectedGroupName && groupColor) {
          // Add the selected sample to the existing group
          const updatedConfiguredSamples = [
            ...configuredSamples,
            { ...sample, groupName: selectedGroupName, color: groupColor }
          ];
  
          // Remove the sample from the unconfigured list
          const remainingSamples = samples.filter(s => s.fileName !== sample.fileName);
  
          // Update state and localStorage
          setConfiguredSamples(updatedConfiguredSamples);
          setSamples(remainingSamples);
  
          localStorage.setItem(`${projectname}-configured-samples`, JSON.stringify(updatedConfiguredSamples));
          localStorage.setItem(`${projectname}-samples`, JSON.stringify(remainingSamples));
        }
      }
    });
  };
  
  return (
    <div className="relative min-h-screen p-6 bg-white">
      <ConfigureTutorialModal 
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen} />
      <div className="flex items-center justify-between mb-4">
        <h1 onClick={() => navigate('/')} className="text-2xl font-semibold cursor-pointer">
          <ArrowBackIcon className="mb-1 mr-2" />Configure Project
        </h1>
        <button 
  className="px-4 py-2 text-sm font-semibold text-blue-700 transition-all bg-white border border-blue-500 rounded-lg hover:bg-blue-50" 
  onClick={handleDiscard}
>
  Reset
</button>

      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <SamplesToBeConfigured
            selectedSamples={selectedSamples}
            setSelectedSamples={setSelectedSamples}
            samples={samples}
            setSamples={setSamples}
            configuredSamples={configuredSamples}
            setConfiguredSamples={setConfiguredSamples}
            handleMoveToExistingGroup={handleMoveToExistingGroup}
          />
        </div>
        <div className="col-span-8">
          <NameAndDefineGroups
            selectedSamples={selectedSamples}
            setConfiguredSamples={setConfiguredSamples}
            setSelectedSamples={setSelectedSamples}
            setSamples={setSamples}
            samples={samples}
            configuredSamples={configuredSamples}
          />
          <ConfiguredSamples
            configuredSamples={configuredSamples}
            setConfiguredSamples={setConfiguredSamples}
            setSamples={setSamples}
          />
        </div>
        <div className="flex justify-between col-span-12 mt-4">
  <button
    className="p-3 font-semibold text-black rounded"
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '170px' }}
    onClick={() => navigate('/')}
  >
    <ArrowBackIcon className="mr-2" />Go back
  </button>
  <div className="flex flex-col items-end text-center"> {/* Align the button and error to the right */}
    <button
      className="p-3 font-semibold text-white bg-teal-700 rounded"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '170px' }}
      onClick={handleSaveConfiguration}
    >
      Save Configuration
    </button>
    {error && (
      <div className="mt-2 text-sm text-right text-red-600"> {/* Align the error message to the right */}
        {error}
      </div>
    )}
  </div>
</div>

      </div>
    </div>
  );
};

export default Configure;
