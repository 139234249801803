import { apiClient } from './apiClient';

export const GetSubscription = () => {
  return apiClient.get('/billing/item-prices');
};
export const GetUserSubscription = () => {
    return apiClient.get('/billing/subscription');
  };
export const BuySubscription= (plan_id:string) => {
    return apiClient.post(`/billing/subscribe/${plan_id} `);
  };
  export const CancelSubscription= () => {
    return apiClient.post(`/billing/cancel-subscription`);
  };